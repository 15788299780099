import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';
import ProductListPage from './ProductListPage';
import LoadingSpinner from './components/LoadingSpinner';
import './CartPage.scss';

const CartPage = () => {
    const { userId } = useAuth();
    const [cartItems, setCartItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const base = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        fetchCartItems();
    }, [userId]);

    const fetchCartItems = async () => {
        if (!userId) {
            setIsLoading(false);
            return;
        }
        try {
            const response = await fetch(`${base}/api/cart/getItems?userId=${userId}`);
            if (!response.ok) throw new Error('Failed to fetch cart items');
            const data = await response.json();
            setCartItems(data);
        } catch (error) {
            console.error('Error fetching cart items:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoveItem = async (productId) => {
        setIsProcessing(true);
        try {
            const response = await fetch(`${base}/api/cart/removeItem`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId, productId }),
            });

            if (!response.ok) throw new Error('Failed to remove item from cart');
            setCartItems(prevItems => prevItems.filter(item => item.product_id !== productId));
        } catch (error) {
            console.error('Error removing item from cart:', error);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleCheckout = () => {
        // Implement checkout logic here
        console.log('Checkout clicked');
    };

    return (
        <div className="cart-page">
            <div className="sticky-header-container">
                <h1 className="page-title">Cart</h1>
            </div>
            {isLoading ? (
                <LoadingSpinner size="large" />
            ) : (
                <ProductListPage 
                    products={cartItems.map(product => ({
                        ...product,
                        isInCart: true  // Force isInCart to be true for all products in cart page
                    }))} 
                    isCartPage={true}
                    onRemoveFromCart={handleRemoveItem}
                    isProcessing={isProcessing}
                />
            )}
            <button 
                className="cart-checkout-button" 
                onClick={handleCheckout}
                disabled={isProcessing || cartItems.length === 0}
            >
                {isProcessing ? 'Processing...' : 'Checkout'}
            </button>
        </div>
    );
};

export default CartPage;
