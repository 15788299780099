import React, { useState, useEffect } from "react";
import './ProfilePage.scss';
import TopIcons from "./TopIcons";
import LoadingSpinner from './components/LoadingSpinner';
import { useAuth } from './AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FiLogOut, FiSettings, FiEdit2, FiImage, FiTrash2, FiHeart, FiMessageSquare, FiX, FiPlus, FiArrowLeft, FiArrowRight, FiMoreVertical } from 'react-icons/fi'; // Add new icons
import FullScreenPost from './FullScreenPost';
import FollowListModal from './FollowListModal'; // Add this import

const ProfilePage = () => {
    const [profile, setProfile] = useState(null); // Initialize profile state
    const [posts, setPosts] = useState([]); // Initialize posts as empty array
    const [newPost, setNewPost] = useState({ 
        content: '', 
        media: [],
        links: [{ description: '', url: '' }],
        mediaPreview: []
    }); // Keep this for new post creation
    const [showNewPostForm, setShowNewPostForm] = useState(false); // Keep this for showing/hiding the new post form
    const { logout, userId: authUserId } = useAuth(); // Use logout from AuthProvider
    const navigate = useNavigate(); // Initialize navigate
    const { userId } = useParams(); // Get userId from URL parameters
    const [username, setUsername] = useState(''); // State for username
    const [error, setError] = useState(''); // State for error messages
    const [followersCount, setFollowersCount] = useState(0); // State for followers count
    const [followingCount, setFollowingCount] = useState(0); // State for following count
    const base = process.env.REACT_APP_API_BASE_URL;
    const local = process.env.REACT_APP_LOCAL_URL;

    // Default profile picture and cover image URLs
    const defaultProfilePicture = "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o=";
    const defaultCoverImage = "https://43bb9d1c7f95c24a2aa8-58ec507a5ae9dad69c8938b724dd8981.ssl.cf1.rackcdn.com/CrazyCap_trainerset01BLACKPR.jpg"; // You can replace this with any default cover image URL

    const [showDropdown, setShowDropdown] = useState(false);

    const [mainProfilePicture, setMainProfilePicture] = useState({ url: defaultProfilePicture, timestamp: Date.now() });
    const [postProfilePicture, setPostProfilePicture] = useState({ url: defaultProfilePicture, timestamp: Date.now() });

    const handleSettingsClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSettingsOptionClick = () => {
        setShowDropdown(false);
        setShowSettingsModal(true);
        setSettingsForm({
            name: profile.name || '',
            username: profile.username || '',
            email: profile.email || '',
            bio: profile.bio || '',
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        });
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    // Add this function to fetch like and comment counts
    const fetchPostCounts = async (postId) => {
        try {
            const [likesResponse, commentsResponse] = await Promise.all([
                axios.get(`${base}/api/post/likes/${postId}?userId=${authUserId}`),
                axios.get(`${base}/api/post/comments/${postId}`)
            ]);
            return {
                likeCount: likesResponse.data.likeCount,
                commentCount: commentsResponse.data.length,
                userLiked: likesResponse.data.userLiked
            };
        } catch (error) {
            console.error('Error fetching post counts:', error);
            return { likeCount: 0, commentCount: 0, userLiked: false };
        }
    };

    // Update the fetchProfileAndPosts function
    const fetchProfileAndPosts = async () => {
        setIsLoading(true);
        try {
            const profileResponse = await axios.get(`${base}/api/profile/${userId}`);
            setProfile(profileResponse.data);
            setUsername(profileResponse.data.username);
            
            // Fetch followers and following counts
            const [followersResponse, followingResponse] = await Promise.all([
                axios.get(`${base}/api/follow/followers/${userId}`),
                axios.get(`${base}/api/follow/following/${userId}`)
            ]);
            
            // Update the counts with the actual lengths of the arrays
            setFollowersCount(followersResponse.data.length);
            setFollowingCount(followingResponse.data.length);

            // Store the full lists
            setFollowers(followersResponse.data);
            setFollowing(followingResponse.data);

            // Fetch profile picture separately
            const profilePictureResponse = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
            if (profilePictureResponse.data.signedUrl) {
                const newTimestamp = Date.now();
                setMainProfilePicture({ url: profilePictureResponse.data.signedUrl, timestamp: newTimestamp });
                setPostProfilePicture({ url: profilePictureResponse.data.signedUrl, timestamp: newTimestamp });
            } else {
                setMainProfilePicture({ url: defaultProfilePicture, timestamp: Date.now() });
                setPostProfilePicture({ url: defaultProfilePicture, timestamp: Date.now() });
            }

            // Fetch posts with like information
            const postsResponse = await axios.get(`${base}/api/post/posts/${userId}`);
            const postsWithLikes = await Promise.all(postsResponse.data.map(async (post) => {
                const likeInfo = await axios.get(`${base}/api/post/likes/${post.id}`, {
                    params: { userId: authUserId }
                });
                return {
                    ...post,
                    userLiked: likeInfo.data.userLiked,
                    likeCount: likeInfo.data.likeCount
                };
            }));
            setPosts(postsWithLikes);

            // Check if the authenticated user is following this profile
            if (authUserId && userId !== authUserId) {
                const isFollowingResponse = await axios.get(`${base}/api/follow/check-following`, {
                    params: { followerId: authUserId, followedId: userId }
                });
                setIsFollowing(isFollowingResponse.data.isFollowing);
            }
        } catch (error) {
            console.error('Error fetching profile data:', error);
            setFollowersCount(0);
            setFollowingCount(0);
            setFollowers([]);
            setFollowing([]);
            setMainProfilePicture({ url: defaultProfilePicture, timestamp: Date.now() });
            setPostProfilePicture({ url: defaultProfilePicture, timestamp: Date.now() });
            setPosts([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchProfileAndPosts();
    }, [userId, authUserId, base]);

    const handleNewPostChange = (e) => {
        setNewPost({ ...newPost, content: e.target.value });
    };

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);
        
        // Filter out non-image files
        const imageFiles = files.filter(file => file.type.startsWith('image/'));
        
        // Show warning if any files were filtered out
        if (imageFiles.length < files.length) {
            alert('Only image files are supported at this time.');
        }
        
        const newMediaFiles = [...newPost.media, ...imageFiles];
        
        // Create preview URLs for new files
        const newPreviews = await Promise.all(
            imageFiles.map(file => {
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve({
                        url: reader.result,
                        type: file.type,
                        file: file
                    });
                    reader.readAsDataURL(file);
                });
            })
        );

        setNewPost(prev => ({
            ...prev,
            media: newMediaFiles,
            mediaPreview: [...prev.mediaPreview, ...newPreviews]
        }));
    };

    const removeMedia = (index) => {
        setNewPost(prev => ({
            ...prev,
            media: prev.media.filter((_, i) => i !== index),
            mediaPreview: prev.mediaPreview.filter((_, i) => i !== index)
        }));
    };

    const reorderMedia = (fromIndex, toIndex) => {
        setNewPost(prev => {
            const newMedia = [...prev.media];
            const newPreviews = [...prev.mediaPreview];
            
            // Reorder both arrays
            const [movedMedia] = newMedia.splice(fromIndex, 1);
            const [movedPreview] = newPreviews.splice(fromIndex, 1);
            
            newMedia.splice(toIndex, 0, movedMedia);
            newPreviews.splice(toIndex, 0, movedPreview);
            
            return {
                ...prev,
                media: newMedia,
                mediaPreview: newPreviews
            };
        });
    };

    // Update the link handlers
    const handleLinkChange = (index, field, value) => {
        const updatedLinks = newPost.links.map((link, i) => {
            if (i === index) {
                return { ...link, [field]: value };
            }
            return link;
        });
        setNewPost({ ...newPost, links: updatedLinks });
    };

    const addLinkFields = () => {
        setNewPost({
            ...newPost,
            links: [...newPost.links, { description: '', url: '' }]
        });
    };

    const removeLinkFields = (index) => {
        setNewPost({
            ...newPost,
            links: newPost.links.filter((_, i) => i !== index)
        });
    };

    // Update the handlePostSubmit function
    const [isPosting, setIsPosting] = useState(false); // New state for posting

    const handlePostSubmit = async (e) => {
        e.preventDefault();
        setIsPosting(true); // Set posting state to true

        try {
            // First, get presigned URLs for all media files
            const fileTypes = Array.from(newPost.media).map(file => file.type);

            const urlsResponse = await axios.post(`${base}/api/post/get-upload-urls`, {
                fileTypes
            });

            // Upload all media files to S3 using the presigned URLs
            const uploadPromises = newPost.media.map((file, index) => {
                const { signedUrl, key, fileType } = urlsResponse.data.uploadUrls[index];
                return axios.put(signedUrl, file, {
                    headers: {
                        'Content-Type': fileType
                    }
                }).then(() => ({ key, fileType }));
            });

            const uploadedMedia = await Promise.all(uploadPromises);

            // Create the post with the S3 keys
            const postData = {
                userId,
                content: newPost.content,
                mediaKeys: uploadedMedia,
                links: newPost.links.filter(link => link.description && link.url)
            };

            const response = await axios.post(`${base}/api/post/create-post`, postData);

            // Reset form and refresh posts
            fetchProfileAndPosts();
            setNewPost({
                content: '',
                media: [],
                links: [{ description: '', url: '' }],
                mediaPreview: []
            });
            setShowNewPostForm(false);
        } catch (error) {
            console.error('Error creating post:', error);
            console.error('Error details:', error.response?.data); // Add detailed error logging
            setError(error.response?.data?.message || 'Failed to create post');
        } finally {
            setIsPosting(false); // Set posting state to false
        }
    };

    const handleProfilePictureChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        try {
            // First, get a presigned URL
            const urlResponse = await axios.post(`${base}/api/profile/get-profile-picture-url`, {
                fileType: file.type
            }, {
                params: { userId }
            });

            // Upload the file directly to S3
            await axios.put(urlResponse.data.uploadUrl, file, {
                headers: {
                    'Content-Type': file.type
                }
            });

            // Update the profile with the new picture info
            const response = await axios.post(`${base}/api/profile/update-profile-picture`, {
                userId,
                key: urlResponse.data.key
            });

            // Update the UI with the new signed URL
            const newTimestamp = Date.now();
            setMainProfilePicture({ url: response.data.signedUrl, timestamp: newTimestamp });
            setPostProfilePicture({ url: response.data.signedUrl, timestamp: newTimestamp });
            
            // Refresh profile data
            fetchProfileAndPosts();
        } catch (error) {
            console.error('Error uploading profile picture:', error);
            setError('Failed to upload profile picture');
        }
    };

    useEffect(() => {
        const fetchProfilePicture = async () => {
            try {
                const response = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
                if (response.data.signedUrl) {
                    setMainProfilePicture({ url: response.data.signedUrl, timestamp: Date.now() });
                    setPostProfilePicture({ url: response.data.signedUrl, timestamp: Date.now() });
                } else {
                    setMainProfilePicture({ url: defaultProfilePicture, timestamp: Date.now() });
                    setPostProfilePicture({ url: defaultProfilePicture, timestamp: Date.now() });
                }
            } catch (error) {
                console.error('Error fetching profile picture:', error);
                setMainProfilePicture({ url: defaultProfilePicture, timestamp: Date.now() });
                setPostProfilePicture({ url: defaultProfilePicture, timestamp: Date.now() });
            }
        };

        fetchProfilePicture();
    }, [userId, base]);

    useEffect(() => {
        const refreshSignedUrl = async () => {
            if (profile && profile.profile_picture_url) {
                try {
                    const signedUrlResponse = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
                    if (signedUrlResponse.data.signedUrl) {
                        setMainProfilePicture({ url: signedUrlResponse.data.signedUrl, timestamp: Date.now() });
                        setPostProfilePicture({ url: signedUrlResponse.data.signedUrl, timestamp: Date.now() });
                    }
                } catch (error) {
                    console.error('Error refreshing signed URL:', error);
                }
            }
        };

        // Refresh the signed URL every 50 minutes (3000000 milliseconds)
        const intervalId = setInterval(refreshSignedUrl, 3000000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [profile, userId, base]);

    const handleDeletePost = async (postId) => {
        try {
            const response = await axios.delete(`${base}/api/post/delete-post/${postId}`, {
                data: { userId: userId }
            });
            if (response.status === 200) {
                // Refresh posts after successful deletion
                fetchProfileAndPosts();
            }
        } catch (error) {
            console.error('Error deleting post:', error);
            setError('Failed to delete post');
        }
    };

    const [fullScreenPostIndex, setFullScreenPostIndex] = useState(null);
    const [showComments, setShowComments] = useState(false);

    const handlePostClick = (post) => {
        const currentPosts = activeTab === 'posts' 
            ? posts.map(p => ({
                ...p,
                username: profile.username
              }))
            : savedPosts;
        const index = currentPosts.findIndex(p => p.id === post.id);
        setFullScreenPostIndex(index);
    };

    const handleFullScreenClose = () => {
        setFullScreenPostIndex(null);
        setShowComments(false);
        // Ensure any other state related to the full-screen view is reset
    };

    const handleFullScreenIndexChange = (newIndex) => {
        setFullScreenPostIndex(newIndex);
    };

    const handleCommentCountChange = (postId, newCount) => {
        setPosts(prevPosts => prevPosts.map(post => 
          post.id === postId 
            ? { ...post, comment_count: newCount } 
            : post
        ));
    };

    const [activeTab, setActiveTab] = useState('posts');
    const [savedPosts, setSavedPosts] = useState([]);

    const fetchSavedPosts = async () => {
        try {
            const response = await axios.get(`${base}/api/post/saved/user/${userId}`);
            // Fetch like information for each saved post
            const postsWithLikes = await Promise.all(response.data.map(async (post) => {
                const likeInfo = await axios.get(`${base}/api/post/likes/${post.id}`, {
                    params: { userId: authUserId }
                });

                // Sort media by the 'order' field from PostMedia table
                const sortedMedia = post.media?.sort((a, b) => a.order - b.order) || [];

                return {
                    ...post,
                    media: sortedMedia,
                    userLiked: likeInfo.data.userLiked,
                    likeCount: likeInfo.data.likeCount
                };
            }));
            setSavedPosts(postsWithLikes);
        } catch (error) {
            console.error('Error fetching saved posts:', error);
            setSavedPosts([]);
        }
    };

    useEffect(() => {
        if (activeTab === 'saved') {
            fetchSavedPosts();
        }
    }, [activeTab]);

    // Update the handleLike function
    const handleLike = async (postId) => {
        if (!authUserId) {
            return;
        }

        // Optimistically update UI for both regular and saved posts
        const updatePostsState = (prevPosts) => prevPosts.map(post => 
            post.id === postId 
                ? { 
                    ...post, 
                    userLiked: !post.userLiked, 
                    likeCount: post.userLiked ? post.likeCount - 1 : post.likeCount + 1 
                } 
                : post
        );

        // Update the appropriate state based on which tab is active
        if (activeTab === 'posts') {
            setPosts(updatePostsState);
        } else {
            setSavedPosts(updatePostsState);
        }

        try {
            const response = await axios.post(`${base}/api/post/togglelike`, {
                userId: authUserId,
                postId
            });
            
            // If the server response doesn't match our optimistic update, revert
            const currentPosts = activeTab === 'posts' ? posts : savedPosts;
            const expectedLikeStatus = !currentPosts.find(p => p.id === postId)?.userLiked;
            if (response.data.liked !== expectedLikeStatus) {
                if (activeTab === 'posts') {
                    setPosts(updatePostsState);
                } else {
                    setSavedPosts(updatePostsState);
                }
            }
        } catch (error) {
            console.error('Error toggling like:', error);
            // Revert optimistic update on error
            if (activeTab === 'posts') {
                setPosts(updatePostsState);
            } else {
                setSavedPosts(updatePostsState);
            }
        }
    };

    const [isFollowing, setIsFollowing] = useState(false);

    useEffect(() => {
        const fetchFollowData = async () => {
            try {
                const followersResponse = await axios.get(`${base}/api/follow/followers/${userId}`);
                const followingResponse = await axios.get(`${base}/api/follow/following/${userId}`);
                
                // Extract the arrays from the nested data structure
                const followersData = followersResponse.data.data || [];
                const followingData = followingResponse.data.data || [];
                
                setFollowersCount(followersData.length);
                setFollowingCount(followingData.length);

                if (authUserId && userId !== authUserId) {
                    // Check if the current user is following the profile user
                    const isFollowing = followersData.some(follower => 
                        follower.id === authUserId
                    );
                    setIsFollowing(isFollowing);
                }
            } catch (error) {
                console.error('Error fetching follow data:', error);
                setFollowersCount(0);
                setFollowingCount(0);
                setIsFollowing(false);
            }
        };

        fetchFollowData();
    }, [userId, authUserId, base]);

    // Update the handleFollowToggle function to properly update counts
    const handleFollowToggle = async () => {
        if (userId === authUserId) return;

        try {
            if (isFollowing) {
                await axios.delete(`${base}/api/follow/unfollow`, { 
                    data: { followerId: authUserId, followedId: userId } 
                });
                setFollowersCount(prev => prev - 1);
            } else {
                await axios.post(`${base}/api/follow/follow`, { 
                    followerId: authUserId, followedId: userId 
                });
                setFollowersCount(prev => prev + 1);
            }
            setIsFollowing(!isFollowing);
        } catch (error) {
            console.error('Error toggling follow:', error);
            // Revert the count if the request fails
            if (isFollowing) {
                setFollowersCount(prev => prev + 1);
            } else {
                setFollowersCount(prev => prev - 1);
            }
        }
    };

    const isOwnProfile = authUserId === userId;

    const [showFollowModal, setShowFollowModal] = useState(false);
    const [followModalType, setFollowModalType] = useState('');
    const [followList, setFollowList] = useState([]);

    const handleFollowClick = async (type) => {
        setFollowModalType(type);
        try {
            const response = await axios.get(`${base}/api/follow/${type}/${userId}`);
            setFollowList(response.data);
            setShowFollowModal(true);
        } catch (error) {
            console.error(`Error fetching ${type} list:`, error);
        }
    };

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [settingsForm, setSettingsForm] = useState({
        name: '',
        username: '',
        email: '',
        bio: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const [settingsMessage, setSettingsMessage] = useState({ type: '', content: '' });

    const handleSettingsChange = (e) => {
        setSettingsForm({ ...settingsForm, [e.target.name]: e.target.value });
    };

    const handleSettingsSubmit = async (e) => {
        e.preventDefault();
        setSettingsMessage({ type: '', content: '' });

        // Check if password is being changed
        const isChangingPassword = settingsForm.newPassword || settingsForm.confirmNewPassword;

        // Validate password change
        if (isChangingPassword) {
            if (!settingsForm.currentPassword) {
                setSettingsMessage({ type: 'error', content: 'Current password is required to change password.' });
                return;
            }
            if (settingsForm.newPassword !== settingsForm.confirmNewPassword) {
                setSettingsMessage({ type: 'error', content: 'New passwords do not match.' });
                return;
            }
        }

        try {
            const response = await axios.put(`${base}/api/profile/update`, {
                userId: userId,
                name: settingsForm.name,
                username: settingsForm.username,
                email: settingsForm.email,
                bio: settingsForm.bio,
                currentPassword: isChangingPassword ? settingsForm.currentPassword : undefined,
                newPassword: isChangingPassword ? settingsForm.newPassword : undefined
            });

            if (response.data.success) {
                setSettingsMessage({ type: 'success', content: 'Settings updated successfully!' });
                // Update local profile state
                setProfile(prevProfile => ({
                    ...prevProfile,
                    name: settingsForm.name,
                    username: settingsForm.username,
                    email: settingsForm.email,
                    bio: settingsForm.bio
                }));
                // Clear password fields
                setSettingsForm(prev => ({
                    ...prev,
                    currentPassword: '',
                    newPassword: '',
                    confirmNewPassword: ''
                }));
            } else {
                setSettingsMessage({ type: 'error', content: response.data.message || 'Failed to update settings.' });
            }
        } catch (error) {
            console.error('Error updating settings:', error);
            setSettingsMessage({ type: 'error', content: error.response?.data?.message || 'An error occurred while updating settings.' });
        }
    };

    const handleClickOutside = (e) => {
        if (e.target.classList.contains('settings-modal-overlay')) {
            setShowSettingsModal(false);
        }
    };

    // Add new state for editing
    const [editingPost, setEditingPost] = useState(null);

    // Add new handler functions
    const handleEditPost = (post) => {
        setEditingPost({
            ...post,
            // Ensure links exists and has at least one empty link object
            links: post.links?.length > 0 
                ? post.links 
                : [{ label: '', url: '' }]
        });
    };

    const handleUpdatePost = async (e) => {
        e.preventDefault();
        try {
            setIsPosting(true);
            
            // Filter out empty links before sending
            const validLinks = editingPost.links.filter(link => 
                link.label.trim() && link.url.trim()
            );

            const response = await axios.put(`${base}/api/post/update/${editingPost.id}`, {
                content: editingPost.content,
                links: validLinks
            });
            
            if (response.data.message === 'Post updated successfully') {
                // Refresh posts and close edit modal
                await fetchProfileAndPosts();
                setEditingPost(null);
            }
        } catch (error) {
            console.error('Error updating post:', error);
            setError('Failed to update post');
        } finally {
            setIsPosting(false);
        }
    };

    // Add a function to handle link changes in edit mode
    const handleEditLinkChange = (index, field, value) => {
        setEditingPost(prev => {
            const updatedLinks = [...prev.links];
            updatedLinks[index] = {
                ...updatedLinks[index],
                [field]: value
            };
            return {
                ...prev,
                links: updatedLinks
            };
        });
    };

    // Add functions to add/remove links in edit mode
    const addEditLinkFields = () => {
        setEditingPost(prev => ({
            ...prev,
            links: [...prev.links, { label: '', url: '' }]
        }));
    };

    const removeEditLinkFields = (index) => {
        setEditingPost(prev => ({
            ...prev,
            links: prev.links.filter((_, i) => i !== index)
        }));
    };

    // Add this state to manage active dropdown
    const [activeDropdown, setActiveDropdown] = useState(null);

    // Add useEffect to handle clicks outside dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (activeDropdown && !event.target.closest('.post-actions')) {
                setActiveDropdown(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        
        // Cleanup listener on unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [activeDropdown]);

    // Add new state for delete confirmation
    const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false, postId: null });

    // Update the delete handler
    const handleDeleteClick = (postId) => {
        setDeleteConfirmation({ show: true, postId });
        setActiveDropdown(null);
    };

    const handleConfirmDelete = async () => {
        try {
            await handleDeletePost(deleteConfirmation.postId);
            setDeleteConfirmation({ show: false, postId: null });
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    // Add this useEffect to handle body scroll
    useEffect(() => {
        if (showNewPostForm || editingPost) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showNewPostForm, editingPost]);

    const [isLoading, setIsLoading] = useState(true); // Add this line

    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [showFollowersModal, setShowFollowersModal] = useState(false);
    const [showFollowingModal, setShowFollowingModal] = useState(false);

    const handleFollowersClick = async () => {
        try {
            const response = await axios.get(`${base}/api/follow/followers/${userId}`);
            setFollowers(response.data);
            setShowFollowersModal(true);
        } catch (error) {
            console.error('Error fetching followers:', error);
        }
    };

    const handleFollowingClick = async () => {
        try {
            const response = await axios.get(`${base}/api/follow/following/${userId}`);
            setFollowing(response.data);
            setShowFollowingModal(true);
        } catch (error) {
            console.error('Error fetching following:', error);
        }
    };

    return (
        <div className="profile-page">
            {isLoading ? (
                <div className="loading-container">
                    <LoadingSpinner size="large" />
                </div>
            ) : (
                <>
                    <div className="cover-image">
                        <img src={defaultCoverImage} alt="Cover" />
                        <div className="top-icons-container">
                            <TopIcons />
                        </div>
                    </div>
                    <div className="profile-header">
                        <div className="profile-picture">
                            <img 
                                src={mainProfilePicture.url} 
                                alt="Profile" 
                                crossOrigin="anonymous"
                                key={mainProfilePicture.timestamp}
                                onError={(e) => {
                                    console.error('Error loading image:', e.target.src);
                                    e.target.src = defaultProfilePicture;
                                }}
                            />
                            {isOwnProfile && (
                                <>
                                    <input
                                        type="file"
                                        id="profile-picture-input"
                                        accept="image/*"
                                        onChange={handleProfilePictureChange}
                                        style={{ display: 'none' }}
                                    />
                                    <label htmlFor="profile-picture-input" className="change-picture-button">
                                        <FiEdit2 />
                                    </label>
                                </>
                            )}
                        </div>
                {isOwnProfile && (
                    <div className="settings-container">
                        <button className="settings-button" onClick={handleSettingsClick}>
                            <FiSettings />
                        </button>
                        {showDropdown && (
                            <div className="settings-dropdown">
                                <button onClick={handleSettingsOptionClick}>Settings</button>
                                <button onClick={handleLogout}>Logout</button>
                            </div>
                        )}
                    </div>
                )}
                <h1 className="name">{profile.name}</h1>
                <h2 className="username">@{profile.username}</h2>
                <div className="follow-stats">
                    <div 
                        className="stat-item clickable" 
                        onClick={handleFollowersClick}
                        style={{ cursor: 'pointer' }}
                    >
                        <span className="count">{followersCount}</span>
                        <span className="label"> followers</span>
                    </div>
                    <span className="stat-separator"> · </span>
                    <div 
                        className="stat-item clickable" 
                        onClick={handleFollowingClick}
                        style={{ cursor: 'pointer' }}
                    >
                        <span className="count">{followingCount}</span>
                        <span className="label"> following</span>
                    </div>
                </div>
                {profile.bio && <p className="bio">{profile.bio}</p>}
                {!isOwnProfile && (
                    <button onClick={handleFollowToggle} className="follow-button">
                        {isFollowing ? 'Unfollow' : 'Follow'}
                    </button>
                )}
                {isOwnProfile && (
                    <button className="new-post-button" onClick={() => setShowNewPostForm(true)}>
                        New Post +
                    </button>
                )}
            </div>

            <div className="profile-tabs">
                {isOwnProfile ? (
                    <>
                        <button 
                            className={`tab-button ${activeTab === 'posts' ? 'active' : ''}`}
                            onClick={() => setActiveTab('posts')}
                        >
                            My Posts
                        </button>
                        <button 
                            className={`tab-button ${activeTab === 'saved' ? 'active' : ''}`}
                            onClick={() => setActiveTab('saved')}
                        >
                            Saved
                        </button>
                    </>
                ) : null}
            </div>

            <div className="user-posts">
                <div className="posts-grid">
                    {(isOwnProfile ? (activeTab === 'posts' ? posts : savedPosts) : posts).map((post, index) => (
                        <div key={post.id} className="post-card" onClick={() => handlePostClick(post)}>
                            {post.media && post.media.length > 0 && (
                                post.media[0].media_type === 'image' ? (
                                    <img 
                                        src={post.media[0].signedUrl} 
                                        alt={`Post ${post.id}`} 
                                        className="post-image"
                                    />
                                ) : (
                                    <video 
                                        src={post.media[0].signedUrl} 
                                        controls 
                                        className="post-image"
                                    />
                                )
                            )}
                            <div className="post-overlay">
                                {post.content && (
                                    <p className="post-caption">{post.content}</p>
                                )}
                                <div className="post-info">
                                    <div className="post-stats">
                                        <span className="post-stat">
                                            <FiHeart /> {post.likeCount}
                                        </span>
                                        <span className="post-stat">
                                            <FiMessageSquare /> {post.commentCount}
                                        </span>
                                    </div>
                                    {isOwnProfile && activeTab === 'posts' && (
                                        <div className="post-actions">
                                            <button 
                                                className="post-action-button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setActiveDropdown(activeDropdown === post.id ? null : post.id);
                                                }}
                                            >
                                                <FiMoreVertical />
                                            </button>
                                            <div className={`post-action-dropdown ${activeDropdown === post.id ? 'active' : ''}`}>
                                                <button onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEditPost(post);
                                                    setActiveDropdown(null);
                                                }}>
                                                    <FiEdit2 /> Edit
                                                </button>
                                                <button onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteClick(post.id);
                                                    setActiveDropdown(null);
                                                }}>
                                                    <FiTrash2 /> Delete
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showNewPostForm && (
                <div className="new-post-overlay">
                    <div className="new-post-form">
                        <h2>Create a New Post</h2>
                        <form onSubmit={handlePostSubmit}>
                            <div className="form-content">
                                <div className="media-preview-section">
                                    <div className="media-grid">
                                        {newPost.mediaPreview?.map((media, index) => (
                                            <div key={index} className="media-preview-item">
                                                {media?.type?.startsWith('image/') ? (
                                                    <img src={media.url} alt={`Preview ${index + 1}`} />
                                                ) : (
                                                    <video src={media.url} />
                                                )}
                                                <div className="media-controls">
                                                    <button 
                                                        type="button"
                                                        onClick={() => removeMedia(index)}
                                                        className="remove-media"
                                                        title="Remove"
                                                    >
                                                        <FiX />
                                                    </button>
                                                    {index > 0 && (
                                                        <button 
                                                            type="button"
                                                            onClick={() => reorderMedia(index, index - 1)}
                                                            className="move-media"
                                                            title="Move left"
                                                        >
                                                            <FiArrowLeft />
                                                        </button>
                                                    )}
                                                    {index < (newPost.mediaPreview?.length || 0) - 1 && (
                                                        <button 
                                                            type="button"
                                                            onClick={() => reorderMedia(index, index + 1)}
                                                            className="move-media"
                                                            title="Move right"
                                                        >
                                                            <FiArrowRight />
                                                        </button>
                                                    )}
                                                </div>
                                                <div className="media-order">{index + 1}</div>
                                            </div>
                                        ))}
                                        {(newPost.mediaPreview?.length || 0) < 10 && (
                                            <label htmlFor="file-upload" className="add-media-button">
                                                <FiPlus />
                                                <span>{!newPost.mediaPreview?.length ? 'Add Photos' : 'Add More'}</span>
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <input
                                    id="file-upload"
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                />

                                <textarea
                                    name="content"
                                    value={newPost.content}
                                    onChange={handleNewPostChange}
                                    placeholder="Write your caption..."
                                    rows="3"
                                />
                                
                                <div className="links-section">
                                    <h3>Add Links</h3>
                                    {newPost.links.map((link, index) => (
                                        <div key={index} className="link-row">
                                            <div className="link-inputs">
                                                <input
                                                    type="text"
                                                    value={link.description}
                                                    onChange={(e) => handleLinkChange(index, 'description', e.target.value)}
                                                    placeholder="Description"
                                                />
                                                <input
                                                    type="url"
                                                    value={link.url}
                                                    onChange={(e) => handleLinkChange(index, 'url', e.target.value)}
                                                    placeholder="URL"
                                                />
                                                <button 
                                                    type="button"
                                                    onClick={() => removeLinkFields(index)}
                                                    className="remove-link-button"
                                                >
                                                    <FiX />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <button 
                                        type="button" 
                                        onClick={addLinkFields}
                                        className="add-link-button"
                                    >
                                        Add Link
                                    </button>
                                </div>
                            </div>

                            <div className="form-buttons">
                                <button type="button" className="cancel-button" onClick={() => setShowNewPostForm(false)}>
                                    Cancel
                                </button>
                                <button type="submit" className="post-button">
                                    Post
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {fullScreenPostIndex !== null && (
                <FullScreenPost
                    posts={activeTab === 'posts' 
                        ? posts.map(post => ({
                            ...post,
                            username: profile.username,
                            userLiked: post.userLiked,
                            likeCount: post.likeCount || 0,
                            media: post.media?.sort((a, b) => a.order - b.order) || [] // Sort media here too
                        }))
                        : savedPosts.map(post => ({
                            ...post,
                            username: post.username || profile.username,
                            userLiked: post.userLiked || false,
                            likeCount: post.likeCount || 0,
                            user_id: post.user_id,
                            comment_count: post.comment_count || post.commentCount || 0,
                            media: post.media?.sort((a, b) => a.order - b.order) || [] // Sort media here too
                        }))
                    }
                    initialPostIndex={fullScreenPostIndex}
                    onClose={handleFullScreenClose}
                    onLike={handleLike}
                    userId={authUserId}
                    onCommentCountChange={handleCommentCountChange}
                    initialShowComments={showComments}
                    onIndexChange={handleFullScreenIndexChange}
                />
            )}

            {showFollowModal && (
                <FollowListModal
                    type={followModalType}
                    list={followList}
                    onClose={() => setShowFollowModal(false)}
                />
            )}

            {showSettingsModal && (
                <div className="settings-modal-overlay" onClick={handleClickOutside}>
                    <div className="settings-modal">
                        <button className="close-modal" onClick={() => setShowSettingsModal(false)}>
                            <FiX />
                        </button>
                        <h2>Account Settings</h2>
                        <form onSubmit={handleSettingsSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={settingsForm.name}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    value={settingsForm.username}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={settingsForm.email}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="bio">Bio</label>
                                <textarea
                                    id="bio"
                                    name="bio"
                                    value={settingsForm.bio}
                                    onChange={handleSettingsChange}
                                    maxLength={160}
                                    placeholder="Tell us about yourself..."
                                />
                                <span className="bio-character-count">
                                    {settingsForm.bio?.length || 0}/160
                                </span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="currentPassword">Current Password</label>
                                <input
                                    type="password"
                                    id="currentPassword"
                                    name="currentPassword"
                                    value={settingsForm.currentPassword}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="newPassword">New Password</label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    value={settingsForm.newPassword}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmNewPassword">Confirm New Password</label>
                                <input
                                    type="password"
                                    id="confirmNewPassword"
                                    name="confirmNewPassword"
                                    value={settingsForm.confirmNewPassword}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            {settingsMessage.content && (
                                <div className={`settings-message ${settingsMessage.type}`}>
                                    {settingsMessage.content}
                                </div>
                            )}
                            <button type="submit" className="save-settings-button">Save Changes</button>
                        </form>
                    </div>
                </div>
            )}

            {editingPost && (
                <div className="new-post-overlay">
                    <div className="new-post-form">
                        <h2>Edit Post</h2>
                        <form onSubmit={handleUpdatePost}>
                            <div className="form-content">
                                <div className="media-preview-section">
                                    <div className="media-grid">
                                        {editingPost.media?.map((media, index) => (
                                            <div key={index} className="media-preview-item edit-mode">
                                                {media.media_type === 'image' ? (
                                                    <img 
                                                        src={media.signedUrl} 
                                                        alt={`Media ${index + 1}`}
                                                    />
                                                ) : (
                                                    <video 
                                                        src={media.signedUrl} 
                                                        controls
                                                    />
                                                )}
                                                <div className="media-order">{index + 1}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <textarea
                                    value={editingPost.content}
                                    onChange={(e) => setEditingPost({ ...editingPost, content: e.target.value })}
                                    placeholder="What's on your mind?"
                                />
                                
                                <div className="links-section">
                                    <h3>Links</h3>
                                    {editingPost.links.map((link, index) => (
                                        <div key={index} className="link-row">
                                            <div className="link-inputs">
                                                <input
                                                    type="text"
                                                    value={link.label || ''}
                                                    onChange={(e) => handleEditLinkChange(index, 'label', e.target.value)}
                                                    placeholder="Description"
                                                />
                                                <input
                                                    type="url"
                                                    value={link.url}
                                                    onChange={(e) => handleEditLinkChange(index, 'url', e.target.value)}
                                                    placeholder="URL"
                                                />
                                                <button 
                                                    type="button"
                                                    onClick={() => removeEditLinkFields(index)}
                                                    className="remove-link-button"
                                                >
                                                    <FiX />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <button 
                                        type="button" 
                                        onClick={addEditLinkFields}
                                        className="add-link-button"
                                    >
                                        Add Link
                                    </button>
                                </div>
                            </div>

                            <div className="form-buttons">
                                <button type="button" className="cancel-button" onClick={() => setEditingPost(null)}>
                                    Cancel
                                </button>
                                <button type="submit" className="post-button">
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {deleteConfirmation.show && (
                <div className="modal-overlay">
                    <div className="confirmation-modal">
                        <h3>Delete Post?</h3>
                        <p>This action cannot be undone.</p>
                        <div className="confirmation-buttons">
                            <button 
                                className="cancel-button"
                                onClick={() => setDeleteConfirmation({ show: false, postId: null })}
                            >
                                Cancel
                            </button>
                            <button 
                                className="delete-button"
                                onClick={handleConfirmDelete}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isPosting && <LoadingSpinner size="large" />} {/* Show spinner when posting */}

            {showFollowersModal && (
                <FollowListModal
                    type="followers"
                    list={followers}
                    onClose={() => setShowFollowersModal(false)}
                    base={base}
                />
            )}

            {showFollowingModal && (
                <FollowListModal
                    type="following"
                    list={following}
                    onClose={() => setShowFollowingModal(false)}
                    base={base}
                />
            )}
        </>
    )}
        </div>
    );
};

export default ProfilePage;